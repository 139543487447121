import React, { Component } from 'react';
import './CSS/Home.css';

export class Home extends Component {
  render() {
    return (
      <div>

      </div>
    )
  }
}

export default Home;