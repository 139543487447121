import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import CacheBuster from 'react-cache-buster';
import packageInfo from '../package.json';
import './App.css';

import Home from './Pages/Home';
import QRCode from './Pages/QRCode';
import CardapioComidas from './Pages/CardapioComidas';
import CardapioBebidas from './Pages/CardapioBebidas';
// import Erro404 from './Pages/Erro404';

const App = () => {
  const isProduction = process.env.NODE_ENV === 'production';
  return (
    <CacheBuster
      currentVersion={packageInfo.version}
      isEnabled={isProduction} //If false, the library is disabled.
      // isVerboseMode={false} //If true, the library writes verbose logs to console.
      // loadingComponent={<Loading />} //If not pass, nothing appears at the time of new version check.
      // metaFileDirectory={'.'} //If public assets are hosted somewhere other than root on your server.
      >

        <Router>
          <Routes>
            <Route exact path='/' element={ <Home /> } />
            <Route exact path='/qr-code' element={ <QRCode /> } />
            <Route exact path='/gastronomia/cardapio-comidas' element={ <CardapioComidas /> } />
            <Route exact path='/gastronomia/cardapio-bebidas' element={ <CardapioBebidas /> } />
            <Route path='*' element={ <Home /> } />
          </Routes>
        </Router>

      </CacheBuster>
  )
}

export default App;
