import React, { Component } from 'react';
import './CSS/CardapioBebidas.css';

const cbLinkDownload = 'https://drive.google.com/uc?export=download&id=14GuS2Z1Bbq3u_xBbxpKlYtPLjWalXYvp';
const idGooglePDF = '14GuS2Z1Bbq3u_xBbxpKlYtPLjWalXYvp';

export class CardapioBebidas extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
       isIOS: false
    }
  }

  componentDidMount() {
    this.checkIOS();
  }

  checkIOS = () => {
    var ua = navigator.userAgent;
    
    const cbAndroid = document.getElementById("cb-android");
    const cbContainer = document.getElementById("cb-container");

    if (/iPhone|iPad|iPod/i.test(ua)) {
      cbAndroid.style.display = 'none';
      cbContainer.style.display = 'flex';
      this.setState({ isIOS: true });
    }
  }

  downloadPDF = () => {
    window.location.href = cbLinkDownload;
  }

  render() {
    return (
      <div className='cbPageContainer'>
        <iframe id='cb-android' className='cbPDF' title='cardapio-Bebidas' src={`https://docs.google.com/viewer?srcid=` + idGooglePDF + `&pid=explorer&efh=false&a=v&chrome=false&embedded=true`} width="100%" height="100%">
          Seu browser não suporta este plugin. Faça o download do documento <a href={cbLinkDownload}>aqui</a>.
        </iframe>

        <div id='cb-container' className='cbContainer'>
          <p>Baixe aqui</p>
          <div className='cbDownloadButton' onClick={this.downloadPDF}>Download</div>
        </div>
      </div>
    )
  }
}

export default CardapioBebidas;