import React, { Component } from 'react';
import './CSS/CardapioComidas.css';

const ccLinkDownload = 'https://drive.google.com/uc?export=download&id=14bR_1kZGFUrXFJub47l8z7TtE57yqj4k';
const idGooglePDF = '14bR_1kZGFUrXFJub47l8z7TtE57yqj4k';

export class CardapioComidas extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
       isIOS: false
    }
  }

  componentDidMount() {
    this.checkIOS();
  }

  checkIOS = () => {
    var ua = navigator.userAgent;
    
    const ccAndroid = document.getElementById("cc-android");
    const ccContainer = document.getElementById("cc-container");

    if (/iPhone|iPad|iPod/i.test(ua)) {
      ccAndroid.style.display = 'none';
      ccContainer.style.display = 'flex';
      this.setState({ isIOS: true });
    }
  }

  downloadPDF = () => {
    window.location.href = ccLinkDownload;
  }

  render() {
    return (
      <div className='ccPageContainer'>
        <iframe id='cc-android' className='ccPDF' title='cardapio-comidas' src={`https://docs.google.com/viewer?srcid=` + idGooglePDF + `&pid=explorer&efh=false&a=v&chrome=false&embedded=true`} width="100%" height="100%">
          Seu browser não suporta este plugin. Faça o download do documento <a href={ccLinkDownload}>aqui</a>.
        </iframe>

        <div id='cc-container' className='ccContainer'>
          <p>Baixe aqui</p>
          <div className='ccDownloadButton' onClick={this.downloadPDF}>Download</div>
        </div>
      </div>
    )
  }
}

export default CardapioComidas;